.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 15px;
  padding-left: 35px;
  padding-right: 35px;
  font-family: 'Raleway', sans-serif;
  font-size: 20px;
  font-weight: 500;
  box-sizing: border-box;
}