.Manifesto {
  color: #4A4A4A;
  width: 100%;
  max-width: 880px;
  line-height: 45px;
  letter-spacing: 1.17px;
}

h1 {
  color: #666666;
}

p {
  padding-top: 20px;
  padding-bottom: 20px;
}

.Idiots {
  color: #D0021B;
  font-style: italic;
}

.Paragraph {
  text-align: left;
  text-indent: 50px;
}

.Quote {
  text-align: left;
  text-indent: 50px;
  border-left: 5px solid #D0021B;
  color: #8E8E93;
  font-style: italic;
  font-weight: 500;
}

.Emphasis {
  color: #D0021B;
  font-weight: 500;
}

.Signature {
  text-align: left;
  padding-bottom: 0px;
  font-family: 'Homemade Apple', cursive;
  font-size: 42px;
}

.Note {
  color: #8E8E93;
  padding-top: 0px;
  text-align: left;
}

a {
  color: #5AC8FA;
}