.StatusBar {
  color: blue;
  width: 100%;
  max-width: 880px;
}

.StatusBarCont {
  background: #EEEEEE;
  width: 100%;
  height: 20px;
  border-radius: 10px;
}

.StatusBarElement {
  width: 0%;
  background: #D0021B;
  height: 20px;
  border-radius: 10px;
}

.NumberOfSignups {
  text-align: left;
  padding-top: 10px;
  padding-bottom: 3px;
  color: #8E8E93;
}

.SmallScript {
  text-align: left;
  padding-bottom: 15px;
  color: #8E8E93;
  font-size: 11px;
}