.EmailSignUp {
  color: grey;
  width: 100%;
  max-width: 880px;
  text-align: left;
  color: #8E8E93;
  font-weight: 500;
  padding-bottom: 100px;
}

.EmailSignUpDiv {
  padding-top: 30px;
  padding-bottom: 20px;
}

.EmailSignUpCont {
  display: flex;
  padding-bottom: 40px;
}

.EmailSignUpContMobile {
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
}

.EmailSignUpInput {
  font-size: 16px;
  padding: 11px;
  width: 250px;
  border-radius: 5px;
  border-width: 1px;
}

.EmailSignUpInputMobile {
  font-size: 16px;
  padding: 11px;
  border-radius: 5px;
  border-width: 1px;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 10px;
}


.EmailSignUpMessage {
  font-size: 11px;
  color: #D0021B;
}

.EmailSignUpButton {
  background: #D0021B;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  color: white;
  border-radius: 5px;
  margin-left: 10px;
  font-weight: 500;
  cursor: pointer;
}

.EmailSignUpButtonMobile {
  background: #D0021B;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  color: white;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
}

.EmailSignUpButton:hover {
  background: #AB0015;
}

.EmailSignUpButton:active {
  background: #8C0011;
}